// Privacy page
import React from "react";
import PrivacyPolicy from "../../config/staticRefs/AXLRY-PrivacyPolicy-12.3.18.pdf";
import Layout from "../../components/Layout";
import { isMobileOnly } from "react-device-detect";

const Privacy = () => {
    return (
        <Layout title={"AXLRY - Privacy Policy"}>
            <div
                className={"center-container"}
                style={{
                    marginTop: "85px",
                    justifyItems: "flex-start",
                    padding: isMobileOnly ? 0 : 8,
                    overflow: isMobileOnly ? "scroll" : "auto"
                }}
            >
                <p>
                    <strong>AXLRY, LLC - PRIVACY POLICY </strong>
                    <a href={PrivacyPolicy} download="Axlry-PrivacyPolicy">
                        (Download the PDF)
                    </a>
                </p>
                <p>
                    <strong>Privacy Policy of </strong>
                    <a href="https://www.axlry.com/">
                        <strong>https://www.axlry.com/</strong>
                    </a>
                </p>
                <p>
                    <strong>Effective date</strong>:December 03, 2018
                </p>
                <p>
                    <strong>AXLRY, LLC </strong>("us", "we", or "our") operates
                    the
                    <strong> https://www.axlry.com/ </strong>websiteand the
                    AXLRY mobile application (hereinafter referred to as the
                    "Service").
                </p>
                <p>
                    This page informs you of our policies regarding the
                    collection, use and disclosure of personal data when you use
                    our Service and the choices you have associated with that
                    data.
                </p>
                <p>
                    We use your data to provide and improve the Service. By
                    using the Service, you agree to the collection and use of
                    information in accordance with this policy. Unless otherwise
                    defined in this Privacy Policy, the terms used in this
                    Privacy Policy have the same meanings as in our Terms of
                    Use.
                </p>
                <p>
                    <strong>Definitions:</strong>
                </p>
                <p>
                    <strong>Service</strong>: Service means the
                    https://www.axlry.com/ website and the AXLRYmobile
                    application operated by AXLRY, LLC
                </p>
                <p>
                    <strong>Personal Data</strong>: Personal Data means data
                    about a living individual who can beidentified from those
                    data (or from those and other information either in our
                    possession or likely to come into our possession).
                </p>
                <p>
                    <strong>Usage Data</strong>: Usage Data is data collected
                    automatically either generated by theuse of the Service or
                    from the Service infrastructure itself (for example, the
                    duration of a page visit).
                </p>
                <p>
                    <strong>Cookies</strong>: Cookies are small files stored on
                    your device (computer or mobiledevice).
                </p>
                <p>
                    <strong>Data Controller</strong>: Data Controller means the
                    natural or legal person who (eitheralone or jointly or in
                    common with other persons) determines the purposes for which
                    and the manner in which any personal informational details
                    are, or are to be, processed. For the purpose of this
                    Privacy Policy, we are a Data Controller of your Personal
                    Data.
                </p>
                <p>
                    <strong>Data Processors (or Service Providers)</strong>:
                    Data Processor (or Service Provider)means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    <strong>Data Subject (or User)</strong>: Data Subject is any
                    living individual who is using ourService and is the subject
                    of Personal Data.
                </p>
                <p>
                    <strong>Information Collection and Use</strong>: We collect
                    several different types ofinformation for various purposes
                    to provide and improve our Service to you.
                </p>
                <p>
                    <strong>Types of Data Collected</strong>
                </p>
                <p>
                    <strong>Personal Data: </strong>While using our Service, we
                    may ask you to provide us withcertain personally
                    identifiable information that can be used to contact or
                    identify you ("Personal Data"). Personally identifiable
                    information may include, but is not limited to: Email
                    address, First name and last name, Phone number, Address,
                    State, Province, ZIP/Postal code, and City.
                </p>
                <p>
                    <strong>Cookies and Usage Data: </strong>We may use your
                    Personal Data to contact you withnewsletters, marketing or
                    promotional materials and other information that may be of
                    interest to you. You may opt out of receiving any, or all,
                    of these communications from us by following the unsubscribe
                    link or instructions provided in any email we send or by
                    contacting us.
                </p>
                <p>
                    <strong>Usage Data: </strong>We may also collect information
                    that your browser sends wheneveryou visit our Service or
                    when you access the Service by or through a mobile device
                    ("Usage Data"). This Usage Data may include information such
                    as your computer's Internet Protocol address (e.g. IP
                    address), browser type, browser version, the pages of our
                    Service that you visit, the time and date of your visit, the
                    time spent on those pages, unique device identifiers and
                    other diagnostic data. When you access the Service with a
                    mobile device, this Usage Data may include information such
                    as the type of mobile device you use, your mobile device
                    unique ID, the IP address of your mobile device, your mobile
                    operating system, the type of mobile Internet browser you
                    use, unique device identifiers and other diagnostic data.
                </p>
                <p>
                    <strong>Location Data: </strong>We may use and store
                    information about your location if you giveus permission to
                    do so ("Location Data"). We use this data to provide
                    features of our Service, to improve and customize our
                    Service. You can enable or disable location services when
                    you use our Service at any time by way of your device
                    settings.
                </p>
                <p>
                    <strong>Tracking &amp; Cookies Data: </strong>We use cookies
                    and similar tracking technologies totrack the activity on
                    our Service and we hold certain information. Cookies are
                    files with a small amount of data, which may include an
                    anonymous unique identifier. Cookies are sent to your
                    browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                </p>
                <p>
                    <strong>Examples of Cookies we use</strong>: Session Cookies
                    - We use Session Cookies tooperate our Service. Preference
                    Cookies <strong>-</strong> We use Preference Cookies to
                    remember your preferences and various settings. Security
                    Cookies <strong>-</strong> We use Security Cookies for
                    security purposes.
                </p>
                <p>
                    <strong>Use of Data</strong>: AXLRY, LLC uses the collected
                    data for various purposes: Toprovide and maintain our
                    Service, To notify you about changes to our Service, to
                    allow you to participate in interactive features of our
                    Service when you choose to do so, to provide customer
                    support, to gather analysis or valuable information so that
                    we can improve our Service, to monitor the usage of our
                    Service, to detect, prevent and address technical issues, to
                    provide you with news, special offers and general
                    information about other goods, services and events which we
                    offer that are similar to those that you have already
                    purchased or enquired about unless you have opted not to
                    receive such information.
                </p>
                <p>
                    <strong>
                        Legal Basis for Processing Personal Data under the
                        General Data Protection Regulation (GDPR)
                    </strong>
                    : If you are from the European Economic Area(EEA), AXLRY,
                    LLC legal basis for collecting and using the personal
                    information described in this Privacy Policy depends on the
                    Personal Data we collect and the specific context in which
                    we collect it. AXLRY, LLC may process your Personal Data
                    because: we need to perform a contract with you, you have
                    given us permission to do so, the processing is in our
                    legitimate interests and it is not overridden by your
                    rights, for payment processing purposes, to comply with the
                    law.
                </p>
                <p>
                    <strong>Retention of Data</strong>: AXLRY, LLC will retain
                    your Personal Data only for as long asis necessary for the
                    purposes set out in this Privacy Policy. We will retain and
                    use your Personal Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required
                    to retain your data to comply with applicable laws), resolve
                    disputes and enforce our legal agreements and policies.
                </p>
                <p>
                    AXLRY, LLC will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period of time, except when this data is used to strengthen
                    the security or to improve the functionality of our Service,
                    or we are legally obligated to retain this data for longer
                    periods.
                </p>
                <p>
                    <strong>Transfer of Data</strong>: Your information,
                    including Personal Data, may be transferredto, and
                    maintained on, computers located outside of your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ from those of your
                    jurisdiction. If you are located outside United States and
                    choose to provide information to us, please note that we
                    transfer the data, including Personal Data, to United States
                    and process it there. Your consent to this Privacy
                </p>
                <p>
                    <br />
                </p>
                <p>
                    Policy followed by your submission of such information
                    represents your agreement to that transfer.
                </p>
                <p>
                    AXLRY, LLC will take all the steps reasonably necessary to
                    ensure that your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of your data and other personal information.
                </p>
                <p>
                    <strong>Disclosure of Data</strong>
                </p>
                <p>
                    <strong>Business Transaction: </strong>If AXLRY, LLC is
                    involved in a merger, acquisition orasset sale, your
                    Personal Data may be transferred. We will provide notice
                    before your Personal Data is transferred and becomes subject
                    to a different Privacy Policy.
                </p>
                <p>
                    <strong>Disclosure for Law Enforcement: </strong>Under
                    certain circumstances, AXLRY, LLCmay be required to disclose
                    your Personal Data if required to do so by law or in
                    response to valid requests by public authorities (e.g. a
                    court or a government agency).
                </p>
                <p>
                    <strong>Legal Requirements: </strong>AXLRY, LLC may disclose
                    your Personal Data in the goodfaith belief that such action
                    is necessary to: to comply with a legal obligation, to
                    protect and defend the rights or property of AXLRY, LLC, to
                    prevent or investigate possible wrongdoing in connection
                    with the Service, to protect the personal safety of users of
                    the Service or the public, to protect against legal
                    liability.
                </p>
                <p>
                    <strong>Security of Data: </strong>The security of your data
                    is important to us but remember thatno method of
                    transmission over the Internet or method of electronic
                    storage is 100% secure. While we strive to use commercially
                    acceptable means to protect your Personal Data, we cannot
                    guarantee its absolute security.
                </p>
                <p>
                    <strong>
                        Our Policy on "Do Not Track" Signals under the
                        California Online Protection Act (CalOPPA)
                    </strong>
                    : We do not support Do Not Track ("DNT"). Do NotTrack is a
                    preference you can set in your web browser to inform
                    websites that you do not want to be tracked. You can enable
                    or disable Do Not Track by visiting the Preferences or
                    Settings page of your web browser.
                </p>
                <p>
                    <strong>
                        Your Data Protection Rights under the General Data
                        Protection Regulation (GDPR)
                    </strong>
                    : If you are a resident of the European Economic Area (EEA),
                    you havecertain data protection rights. AXLRY, LLC aims to
                    take reasonable steps to allow you to correct, amend, delete
                    or limit the use of your Personal Data.
                </p>
                <p>
                    If you wish to be informed about what Personal Data we hold
                    about you and if you want it to be removed from our systems,
                    please contact us.
                </p>
                <p>
                    <br />
                </p>
                <p>
                    In certain circumstances, you have the following data
                    protection rights:
                    <strong>The right</strong>
                    <strong>
                        to access, update or delete the information we have on
                        you.
                    </strong>
                    Whenevermade possible, you can access, update or request
                    deletion of your Personal Data directly within your account
                    settings section. If you are unable to perform these actions
                    yourself, please contact us to assist you.
                    <strong>The right of</strong>
                    <strong>rectification. </strong>You have the right to have
                    your information rectified if thatinformation is inaccurate
                    or incomplete. <strong>The right to object.</strong> You
                    have the right to object to our processing of your Personal
                    Data.
                    <strong>The right of restriction.</strong> You have the
                    right to request that we restrict the processing of your
                    personal information.
                    <strong>The right to data portability.</strong> You have the
                    right to be provided with a copy of the information we have
                    on you in a structured, machine-readable and commonly used
                    format.
                    <strong>The right to withdraw consent.</strong> You also
                    have the right to withdraw your consent at any time where
                    AXLRY, LLC relied on your consent to process your personal
                    information.
                </p>
                <p>
                    Please note that we may ask you to verify your identity
                    before responding to such requests. You have the right to
                    complain to a Data Protection Authority about our collection
                    and use of your Personal Data. For more information, please
                    contact your local data protection authority in the European
                    Economic Area (EEA).
                </p>
                <p>
                    <strong>Service Providers</strong>: We may employ third
                    party companies and individuals tofacilitate our Service
                    ("Service Providers"), provide the Service on our behalf,
                    perform Service-related services or assist us in analyzing
                    how our Service is used. These third parties have access to
                    your Personal Data only to perform these tasks on our behalf
                    and are obligated not to disclose or use it for any other
                    purpose.
                </p>
                <p>
                    <strong>Analytics</strong>: We may use third-party Service
                    Providers to monitor and analyze theuse of our Service.
                </p>
                <p>
                    <strong>Google Analytics</strong>: Google Analytics is a web
                    analytics service offered by Googlethat tracks and reports
                    website traffic. Google uses the data collected to track and
                    monitor the use of our Service. This data is shared with
                    other Google services. Google may use the collected data to
                    contextualize and personalize the ads of its own advertising
                    network. For more information on the privacy practices of
                    Google, please visit the Google Privacy &amp; Terms web
                    page:
                    <a href="https://policies.google.com/privacy?hl=en">
                        <strong>https://</strong>
                    </a>
                    <a href="https://policies.google.com/privacy?hl=en">
                        <strong>policies.google.com/privacy?hl=en</strong>
                    </a>
                </p>
                <p>
                    <strong>Firebase</strong>: Firebase is analytics service
                    provided by Google Inc.
                </p>
                <p>
                    You may opt-out of certain Firebase features through your
                    mobile device settings, such as your device advertising
                    settings or by following the instructions provided by Google
                    in their Privacy Policy:
                    <a href="https://policies.google.com/privacy?hl=en">
                        <strong>
                            https://policies.google.com/privacy?hl=en
                        </strong>
                    </a>
                </p>
                <p>
                    We also encourage you to review the Google's policy for
                    safeguarding your data:
                    <a href="https://support.google.com/analytics/answer/6004245">
                        <strong>
                            https://support.google.com/analytics/answer/6004245
                        </strong>
                    </a>
                    .
                </p>
                <p>
                    <br />
                </p>
                <p>
                    For more information on what type of information Firebase
                    collects, please visit the Google Privacy &amp; Terms web
                    page:
                    <a href="https://policies.google.com/privacy?hl=en">
                        <strong>https://policies.google.com/privacy?</strong>
                    </a>
                    <a href="https://policies.google.com/privacy?hl=en">
                        <strong>hl=en</strong>
                    </a>
                </p>
                <p>
                    <strong>Payments</strong>: We may provide paid products
                    and/or services within the Service. Inthat case, we use
                    third-party services for payment processing (e.g. payment
                    processors). We will not store or collect your payment card
                    details. That information is provided directly to our
                    third-party payment processors whose use of your personal
                    information is governed by their Privacy Policy. These
                    payment processors adhere to the standards set by PCI-DSS as
                    managed by the PCI Security Standards Council, which is a
                    joint effort of brands like Visa, MasterCard, American
                    Express and Discover. PCI-DSS requirements help ensure the
                    secure handling of payment information. The payment
                    processors we work with are: <strong>Stripe</strong>: Their
                    Privacy Policy can be viewed at:
                    <a href="https://stripe.com/us/privacy">
                        <strong>https://stripe.com/</strong>
                    </a>
                    <a href="https://stripe.com/us/privacy">
                        <strong>us/privacy</strong>
                    </a>
                </p>
                <p>
                    <strong>Links to Other Sites</strong>: Our Service may
                    contain links to other sites that are notoperated by us. If
                    you click a third party link, you will be directed to that
                    third party's site. We strongly advise you to review the
                    Privacy Policy of every site you visit. We have no control
                    over and assume no responsibility for the content, privacy
                    policies or practices of any third party sites or services.
                </p>
                <p>
                    <strong>Children's Privacy</strong>:Our Service does not
                    address anyone under the age of 18("Children"). We do not
                    knowingly collect personally identifiable information from
                    anyone under the age of 18. If you are a parent or guardian
                    and you are aware that your Child has provided us with
                    Personal Data, please contact us. If we become aware that we
                    have collected Personal Data from children without
                    verification of parental consent, we take steps to remove
                    that information from our servers.
                </p>
                <p>
                    <strong>Changes to This Privacy Policy</strong>: We may
                    update our Privacy Policy from timeto time. We will notify
                    you of any changes by posting the new Privacy Policy on this
                    page. We will let you know via email and/or a prominent
                    notice on our Service, prior to the change becoming
                    effective and update the "effective date" at the top of this
                    Privacy Policy. You are advised to review this Privacy
                    Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.
                </p>
                <p>
                    <strong>Contact Us</strong>: If you have any questions about
                    this Privacy Policy, please contact
                </p>
                <p>
                    us by email: <strong>support@axlry.com</strong>
                </p>
                <p>
                    <br />
                    <br />
                </p>
                <p>AXLRY, LLC - Privacy Policy</p>
            </div>
        </Layout>
    );
};

export default Privacy;
